<template>
	<ion-page>
		<ion-header :translucent="true" class="app-header">
			<ion-toolbar>
				<ion-buttons slot="end" v-if="!isIos() && dekoId">
					<ion-button
						class="ion-hide-md-down"
						color="primary"
						@click="() => router.push('/new')"
					>Freien Auftrag hinzufügen</ion-button>
					<ion-button class="ion-hide-md-up" @click="openPopover">
						<ion-icon :icon="ellipsisHorizontal" size="medium" v-if="isIos()" />
						<ion-icon v-else slot="icon-only" :icon="ellipsisVertical" />
					</ion-button>
				</ion-buttons>
				<ion-title>Adscreener</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true" class="app-main-content">
			<ion-refresher slot="fixed" @ionRefresh="refresh($event)">
				<ion-refresher-content></ion-refresher-content>
			</ion-refresher>
			<ion-header collapse="condense">
				<ion-toolbar>
					<ion-title size="large">Adscreener</ion-title>
					<ion-buttons slot="end" v-if="dekoId">
						<ion-button
							class="ion-hide-md-down"
							color="primary"
							@click="() => router.push('/new')"
						>Freien Auftrag hinzufügen</ion-button>

						<ion-button @click="openPopover" class="ion-hide-md-up">
							<ion-icon :icon="ellipsisHorizontal" size="medium" v-if="isIos()" />
							<ion-icon v-else slot="icon-only" :icon="ellipsisVertical" />
						</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-header>

			<ion-list v-if="dekoId">
				<ion-searchbar debounce="500" @ionChange="search"></ion-searchbar>

				<message-list-item v-for="message in messages" :key="message.id" :message="message" />
				<template v-if="!messages.length">
					<ion-item v-for="n in 5" :key="n">
						<div class="grid-layout custom-skeleton">
							<ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
							<ion-skeleton-text animated style="width: 5rem"></ion-skeleton-text>
							<ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
							<ion-skeleton-text animated style="width: 5rem"></ion-skeleton-text>
							<ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
							<ion-skeleton-text animated style="width: 5rem"></ion-skeleton-text>
						</div>
					</ion-item>
				</template>
			</ion-list>
			<ion-item v-else>
				<h1>Keine Logindaten vorhanden, bitte an den Administrator wenden</h1>
			</ion-item>
			<ion-infinite-scroll
				@ionInfinite="loadData($event)"
				threshold="100px"
				id="infinite-scroll"
				:disabled="isDisabled"
			>
				<ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading..."></ion-infinite-scroll-content>
			</ion-infinite-scroll>
		</ion-content>
	</ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { mapGetters, useStore } from 'vuex';

import {
	IonContent,
	IonHeader,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonList,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonSearchbar,
	IonItem,
	IonSkeletonText,
	popoverController
} from "@ionic/vue";
import {
	addOutline,
	addCircleOutline,
	ellipsisHorizontal,
	ellipsisVertical
} from "ionicons/icons";

import NavPopover from '../components/NavPopover.vue';
import MessageListItem from '@/components/MessageListItem.vue';

export default defineComponent({
	name: "Home",
	components: {
		IonContent,
		IonHeader,
		IonInfiniteScroll,
		IonInfiniteScrollContent,
		IonList,
		IonPage,
		IonRefresher,
		IonRefresherContent,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonButton,
		IonIcon,
		IonSearchbar,
		IonItem,
		IonSkeletonText,
		MessageListItem,
	},
	data() {
		return {
			addOutline,
			addCircleOutline,
			ellipsisHorizontal,
			ellipsisVertical,
			popover: null,
		};
	},
	setup() {
		const isDisabled = ref(false);
		const searchTerm = ref('');
		const start = ref(0);
		const end = ref(49);

		const store = useStore();
		const router = useRouter();


		if (store.getters.getSessionId) {
			store.dispatch('jobs/getList', {
				id: store.getters.getSessionId,
				start: start.value,
				end: end.value,
			});
		}

		const loadData = (ev) => {
			start.value = start.value + 50;
			end.value = end.value + 50;

			store.dispatch('jobs/getList', {
				id: store.getters.getSessionId,
				start: start.value,
				end: end.value,
				term: searchTerm.value || undefined,
			})
				.then(() => ev.target.complete());

			if (store.state.jobs.list.length === store.state.jobs.listLength) {
				ev.target.disabled = true;
			}
		};

		const search = ({ detail: { value } }) => {
			searchTerm.value = value;
			start.value = 0;
			end.value = 49;
			store.dispatch('jobs/resetList');
			store.dispatch('jobs/getList', {
				id: store.getters.getSessionId,
				start: start.value,
				end: end.value,
				term: searchTerm.value
			});
		};

		const dekoId = store.getters.getDekorateurId;

		return {
			router,
			isDisabled,
			loadData,
			search,
			dekoId
		};
	},
	computed: {
		...mapGetters({
			messages: 'jobs/getJobs',
			listLength: 'jobs/getListLength',
		}),

	},
	methods: {
		refresh: (ev) => {
			setTimeout(() => {
				ev.detail.complete();
			}, 3000);
		},

		isIos: () => {
			const win = window;
			return win && win.Ionic && win.Ionic.mode === "ios";
		},

		async openPopover(ev) {
			this.popover = await popoverController
				.create({
					component: NavPopover,
					cssClass: 'my-custom-class',
					event: ev,
					translucent: false
				});
			await this.popover.present();
		},

	},
	async ionViewWillLeave() {
		if (this.popover) {
			await this.popover.dismiss();
		}
	}
});
</script>
<style scoped>
.grid-layout {
	display: grid;
	gap: 0.25rem 0.75rem;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	width: 100%;
	padding: 1rem 0;
}

.app-header {
	display: flex;
}

.user {
	--inner-border-width: 0;
	flex: 1 0 auto;
}

.md .user {
	margin-top: 1rem;
	margin-right: 1rem;
}

.ios .user {
	margin-top: 0.5rem;
	margin-right: 0.5rem;
}

.ion-page {
	max-width: 1440px;
	margin: 0 auto;
}

.app-main-content {
	max-width: 1440px;
	align-self: center;
}
</style>
